














import { Component, Vue, Prop } from 'vue-property-decorator';
import BoxPage from '@/themes/v1/components/BoxPage.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import FaqAndGuideLinks from '@/themes/v1/components/FaqAndGuideLinks.vue';

@Component({
  components: { FaqAndGuideLinks, SectionCircleLink, BoxContent, BoxPage },
})
export default class Success extends Vue {
  @Prop({ type: String })
  title!: string;

  @Prop({ type: String })
  subtitle!: string;

  @Prop({ type: String })
  text!: string;
}
